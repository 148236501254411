import Swiper, { Mousewheel, FreeMode, Lazy, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/lazy/lazy.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const charts = document.querySelector('.js-charts'),
          products = document.querySelector('.js-products'),
          reviews = document.querySelector('.js-reviews');

/*
    const chartsCarousel = function() {
        const swiper = new Swiper(charts, {
            modules: [Mousewheel, Navigation, Pagination],
            direction: 'vertical',
            mousewheel: {
                releaseOnEdges: true,
                sensitivity: .1,
                thresholdDelta: 10
            },
            slidesPerView: 1,
        });
    }
*/
    
    const productsCarousel = function() {
        const swiper = new Swiper(products, {
            modules: [FreeMode, Lazy, Navigation, Pagination],
            centeredSlides: true,
            freeMode: true,
            lazy: true,
            loop: true,
            navigation: {
                nextEl: products.querySelector('.swiper-button-next'),
                prevEl: products.querySelector('.swiper-button-prev'),
            },
            preloadImages: false,
            spaceBetween: 20,
            slidesPerView: 'auto',
            pagination: {
                el: products.querySelector('.swiper-pagination'),
                    clickable: true,
            },
            watchSlidesProgress: true
        });
    }
    
    const reviewsCarousel = function() {
        const swiper = new Swiper(reviews, {
            modules: [FreeMode, Navigation, Pagination],
            centeredSlides: false,
            freeMode: false,
            
    	    breakpoints: {
        	    769: {
                     centeredSlides: false,
                     freeMode: true,
                     slidesPerView: 2,
                     spaceBetween: 20,
                },
                1025: {
                     centeredSlides: false,
                     freeMode: true,
                     slidesPerView: 3,
                     spaceBetween: 60,
                },
            },
            loop: false,
            navigation: {
                nextEl: document.querySelector('.c-reviews').querySelector('.swiper-button-next'),
                prevEl: document.querySelector('.c-reviews').querySelector('.swiper-button-prev')
            },
            pagination: {
                el: reviews.querySelector('.swiper-pagination'),
                clickable: true
            },
            slidesPerView: 'auto',
            touchMoveStopPropagation: false,
            //slidesOffsetAfter: 20,
    	    spaceBetween: 20,
        });
    };

    window.carousels = function() {
        charts ? chartsCarousel() : false;
        products ? productsCarousel() : false;
        reviews ? reviewsCarousel() : false;
    }

}, false)
