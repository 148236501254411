import { gsap } from "gsap";
import smoothscroll from 'smoothscroll-polyfill';
import ScrollTrigger from "gsap/ScrollTrigger";
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);

    window.runScroll = (el, o) => {
        let offsetTop = document.querySelector(el).offsetTop,
            topbar_height = document.querySelector('.js-topbar').clientHeight;

        o === undefined ? o = 0 : false;
        
        scroll({
            top: offsetTop-topbar_height - o,
            behavior: "smooth"
        })
        
        if (document.querySelector('.c-nav').classList.contains('is-visible')) {
            window.hideMenu();
        }
                
        setTimeout(function() {
            ScrollTrigger.refresh();
        }, 1000)
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;  
                
            if (target === '#wyprobuj') {
               e.currentTarget.classList.contains('advice') ? window.listComponent.setDepartment(true) : false;
            }
                
            document.querySelector(target) ? window.runScroll(target, offset) : false;
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
