document.addEventListener('DOMContentLoaded', () => {
    
    const btn = document.querySelectorAll('.js-lessmore');

    const init = function() {
        let lang = 0;
        
        for (let i = 0; i < btn.length; i++) {
            btn[i].addEventListener('click', (e) => {
                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
                
                let target = document.querySelector(e.currentTarget.dataset.el);
    
                if (target.classList.contains('all-items')) {
                    target.classList.remove('all-items');
                    btn[i].innerHTML = JSON.parse(e.currentTarget.dataset.more)[lang];
                } else {
                    target.classList.add('all-items');
                    btn[i].innerHTML = JSON.parse(e.currentTarget.dataset.less)[lang];
                }
            })
        }
    };

    btn ? init() : false;
    
}, false);
